const Elements = {

    getMetaNavigationElement() {
        return document.getElementById('jsReactMetaNav');
    },

    getNavigationElement() {
        return document.getElementById('jsReactNav');
    },

    getMenuMobile() {
        return document.getElementById('jsReactMenuMobile');
    },

    getMiniCartElement() {
        return document.getElementById('jsReactNavMiniCart');
    },

    getInputSpinnerElementOnList() {
        return document.querySelectorAll('.jsReactInputSpinner');
    },

    getInputSpinnerElementsForModal() {
        return document.querySelectorAll('.modal .jsReactInputSpinner');
    },

    getModalElement() {
        return document.getElementById('jsReactModal');
    },

    getDropdownElement() {
        return document.getElementById('jsDropdown');
    },

    getDropdownElements() {
        return document.querySelectorAll('.jsDropdownReact');
    },

    getSelectboxElement() {
        return document.getElementById('jsSelectbox');
    },

    getSelectboxNoInputElement() {
        return document.getElementById('jsSelectboxNoInput');
    },

    getSingleActionButtonElement() {
        return document.getElementById('jsSingleActionButton');
    },

    getFlyoutWithText() {
        return document.getElementById('jsFlyoutWithText');
    },

    getPrimaryButton() {
        return document.getElementById('jsPrimaryButton');
    },

    getSecondaryButton() {
        return document.getElementById('jsSecondaryButton');
    },

    getFlyoutInfoBox() {
        return document.getElementById('jsRecentOrdersFlyout');
    },

    getFlyoutManufacturerBox() {
        return document.getElementById('jsManufacturerBox');
    },

    getAddToCartButtons() {
        return document.querySelectorAll('.jsAddToCartButtonsReact');
    },

    getAddToRfqButtons() {
        return document.querySelectorAll('.jsAddToRfqButtonsReact');
    },

    getAddToCadButtons() {
        return document.querySelectorAll('.jsAddToCadButtonsReact');
    },

    getAddToWishlistButtons() {
        return document.querySelectorAll('.jsAddToWishlistButtonsReact');
    }
};

export default Elements;
