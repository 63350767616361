import React, {useEffect, useState} from 'react';
import LocalStorage from "../utils/localStorage";
import {__} from "../i18n";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const InputSpinner = ({
        id,
        productId,
        ve,
        productAttributeDawn,
        input,
        itemId,
        button,
        forceLoadingComponent = false,
        name = "qty"
    }) => {

    const dataId = id;
    const dataVe = Number(ve);
    const dataProductId = productId;
    // jsAnbruch
    let dawn = productAttributeDawn;

    if (dawn === "0") {
        dawn = true;
    } else if (dawn === "1") {
        dawn = false;
    }

    // jsStückzahl
    const numberOfPiecesPermission = LocalStorage.getNumberOfPiecesPermission();
    let cartItemId = false;
    if (itemId) {
        cartItemId = true;
    }

    const dataMinValue = dataVe;
    const dataMaxValue = dataVe * 10000;

    //let myRef = useRef(null);
    const [inputValue, setInputValue] = useState(input !== null && input !== undefined ? Number(input) : dataVe);
    const [isDecreaseButtonDisabled, setIsDecreaseButtonDisabled] = useState(true);
    const [isIncreaseButtonDisabled, setIsIncreaseButtonDisabled] = useState(false);
    const [allowSingleItemQty, setAllowSingleItemQty] = useState(!!(numberOfPiecesPermission && dawn));
    const [minAllowedValue, setMinAllowedValue] = useState(Number(!!(numberOfPiecesPermission && dawn)));
    const [isModalOpen, setIsOpen] = useState(false);
    const [currentElement, setCurrentElement] = useState(false);

    let element = null;
    if (itemId) {
        element = document.querySelector(`#jsReactInputSpinner[data-item-id="${itemId}"]`);
    } else {
        element = document.querySelector(`[data-id="${dataId}"]`);
    }

    useEffect(() => {
        // Use the updated state value here
        updateButtons();
        checkMinValue();
        checkMaxValue();
        setButtonValue();
        updateList();

    }, [inputValue]);

    function handleDecreasingClick() {
        setInputValue((prevValue) => {
            const newValue = Number(prevValue) - dataVe;
            return newValue < dataMinValue ? dataMinValue : newValue;
        });
    }

    function handleIncreasingClick() {
        setInputValue((prevValue) => {
            const newValue = Number(prevValue) + dataVe;
            return newValue > dataMaxValue ? dataMaxValue : newValue;
        });
    }

    function handleOnChange(event) {
        setCurrentElement(event.target);
        const value = event.target.value.trim();
        setInputValue(value.replace(/^0+/, ""));
    }

    function handleCloseClick() {
        document.querySelector('.panel.wrapper').style.zIndex = null;
        setIsOpen(false);
        if (!allowSingleItemQty) {
            // See REYHER-3420
            const closestMultiple = inputValue <= dataVe ? dataVe : Math.round((inputValue / dataVe) + 1) * dataVe;
            setInputValue(closestMultiple);
        }

        setButtonValue();
    }

    function updateList() {
        if (button) {
            const inputInsideButton = button.querySelectorAll('input[name="qty"]');
            if (inputInsideButton) {
                inputInsideButton.forEach(item => {
                    //item.focus();
                    item.value = inputValue;
                    item.blur();

                    const customEvent = new Event('triggerQuantityUpdateReact', {
                        bubbles: true,
                        cancelable: true,
                    });

                    item.dispatchEvent(customEvent);

                    if (currentElement) {
                        currentElement.focus();
                    }
                });
            }
        }
    }

    function handleBlur(event) {
        const isAlreadyModalOpen
            = document.querySelectorAll('.inp-spinner__quantity-container--active').length

        let currentValue = Number(inputValue);

        if (currentValue === 0) {
            setInputValue(Number(dataMinValue));
        }

        if (currentValue < dataMinValue) {
            setInputValue(Number(dataMinValue));
        }

        if (
            !allowSingleItemQty
            && isAlreadyModalOpen === 0
            && (inputValue % dataVe !== 0)
        ) {
            document.querySelector('.panel.wrapper').style.zIndex = "1";
            setIsOpen(true);
        }

        if (allowSingleItemQty) {
            if (event.target.value === "") {
                setInputValue(dataVe);
            } else {
                setInputValue(Number(event.target.value));
            }
        }
    }

    function updateButtons() {
        let currentValue = Number(inputValue);
        let tr = element.closest('tr');

        if (currentValue > dataMinValue) {
            setIsDecreaseButtonDisabled(false);
        }

        if (currentValue < dataMinValue) {
            setIsDecreaseButtonDisabled(true);
            if (tr) {
                if (tr.querySelector('.form-checkbox')) {
                    tr.querySelector('.form-checkbox').checked = false;
                } else {
                    tr.querySelector('.form-radiobutton-reyher').checked = false;
                }
            }
        }

        if (currentValue >= dataMaxValue) {
            setIsIncreaseButtonDisabled(true);
        }

        if (currentValue < dataMaxValue) {
            setIsIncreaseButtonDisabled(false);
        }
    }

    function checkMinValue() {
        if (!allowSingleItemQty || inputValue === 0) {

            let currentValue = Number(inputValue);
            if (currentValue <= dataMinValue) {
                setIsDecreaseButtonDisabled(true);
            } else {
                setIsDecreaseButtonDisabled(false);
            }
        }
    }

    function checkMaxValue() {
        //sets user input to maxValue if its above
        if (inputValue > dataMaxValue) {
            setInputValue(Number(dataMaxValue));
        }

        let currentValue = Number(inputValue);
        if (dataMaxValue && currentValue === dataMaxValue) {
            setIsIncreaseButtonDisabled(true);
        }

        if (inputValue < dataMaxValue) {
            setIsIncreaseButtonDisabled(false);
        }
    }

    function setButtonValue() {
        const tr = element.closest('tr');
        const input = tr?.querySelector('.form-input-set__cart input[name="qty"]');
        const inputRioScan = tr?.querySelector('.add2cart-rioscan-ajax:not(.no-input)');

        if (input) {
            input.value = inputValue;
        } else if (inputRioScan) {
            inputRioScan.dataset.qty = inputValue.toString();
        }

        let customDetailEvent = new CustomEvent('changeQty', {
            'detail': {
                'element': element,
                'quote_item_id': itemId,
                'qty': inputValue
            }
        });
        document.dispatchEvent(customDetailEvent);
    }

    if (LocalStorage.getArticleCheckAvailabilityPermission() || LocalStorage.getCartRequestPermission() || forceLoadingComponent) {
        return (
            <div className={`inp-spinner`}
                 id={dataId}
                 data-ve={dataVe}
                 data-product-id={dataProductId}
                 data-item-id={itemId}>
                <button type="button"
                        onClick={handleDecreasingClick}
                        disabled={isDecreaseButtonDisabled}
                        className={`inp-spinner__button ${isDecreaseButtonDisabled ? 'inp-spinner__button--disabled' : ''} inp-spinner__decrease`}>-</button>
                <input type="number"
                       name={name}
                       data-field-name="qty"
                       className="inp-spinner__input"
                       data-steps={dataVe}
                       data-id={itemId ? itemId : undefined}
                       data-qty={inputValue}
                       data-cart-item-id={cartItemId ? dataId.replace(/^sp-/, '') : undefined}
                       data-minvalue={dataMinValue}
                       data-maxvalue={dataMaxValue}
                       value={inputValue}
                       data-role={itemId ? "cart-item-qty" : undefined}
                       pattern="[0-9]+"
                       onChange={handleOnChange}
                       //onWheel={event => event.target.blur()}
                       onBlur={handleBlur}
                />
                <button type="button"
                        onClick={handleIncreasingClick}
                        disabled={isIncreaseButtonDisabled}
                        className={`inp-spinner__button ${isIncreaseButtonDisabled ? 'inp-spinner__button--disabled' : ''} inp-spinner__increase`}>+</button>

                <div className={`inp-spinner__quantity-container${isModalOpen ? '--active' : ''}`}>
                    <div className="inp-spinner__quantity-backdrop"></div>
                    <div className="inp-spinner__quantity-inner-container">
                        <div className="inp-spinner__quantity-header">
                            {__("Please select a multiple of the packaging unit:")}
                        </div>
                        <div className="inp-spinner__quantity-content">
                            {Array.from({length: 10}, (_, i) => {
                                const buttonValue = Math.floor(inputValue / dataVe) + i;
                                if (buttonValue === 0) return null; // Skip rendering when buttonValue is 0
                                return (<button
                                    className={"inp-spinner--selector"}
                                    key={i}
                                    value={buttonValue}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setInputValue(Number(buttonValue * dataVe));
                                        document.querySelector('.panel.wrapper').style.zIndex = null;
                                        setIsOpen(false);
                                        setButtonValue();
                                        updateButtons();
                                    }}
                                >
                                    {dataVe * buttonValue} ({buttonValue} {buttonValue === 1 ? "Verpackungseinheit" : "Verpackungseinheiten"})
                                </button>);
                            })}
                        </div>
                        <div className="inp-spinner__quantity-footer">
                            <button type="button"
                                    className="button-reyher button-reyher--primary inp-spinner__quantity-cancel"
                                    onClick={handleCloseClick}
                                    title="">
                                <span className="button-reyher__link">{__("Cancel")}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InputSpinner;
